import { Image } from '@builder.io/react'
import React, { useEffect, useState } from 'react'
import IO from '../Io'

const CollageItem = ({ block, className }) => (
  <article
    className={`${className}  `}
    data-animation-scroll="fade-in-up"
    data-animation-delay="300"
  >
    <Image image={block.image} altText={`${block.alt}`} />
  </article>
)

const calcLayout = (numOfImg, selection) => {
  let output = parseInt(selection)
  if (numOfImg === 2) {
    // If there are 2 images, then the layout has 2 options. If there are 3 images, then the layout has 4 options.
    // output = parseInt(selection) % 2 == 0 ? 2 : 1;
  }
  return output
}

export const Collage = ({
  leftBlock,
  rightBlock,
  leftRightSwitch,
  leftBlockLayout,
  rightBlockLayout,
  blockHeight_desktop,
  blockHeight_mobile,
  sec_padding_desk,
  sec_padding_mobile
}) => {
  useEffect(() => {
    new IO()

    /* for mobile styles */
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [isMobile, setIsMobile] = useState(false)

  const lefties = leftBlock ? leftBlock.length : 0
  const righties = rightBlock ? rightBlock.length : 0

  let leftLayout = calcLayout(lefties, leftBlockLayout)
  let rightLayout = calcLayout(righties, rightBlockLayout)

  const blockHeight_d =
    blockHeight_desktop && blockHeight_desktop !== ''
      ? blockHeight_desktop
      : '450px'

  const blockHeight_m =
    blockHeight_mobile && blockHeight_mobile !== ''
      ? blockHeight_mobile
      : '450px'

  return (
    <section
      className={`section Collage`}
      aria-label={` Collage `}
      style={{ padding: isMobile ? sec_padding_mobile : sec_padding_desk }}
    >
      <div className="container">
        <div
          className={`section_columns columns ${
            leftRightSwitch ? ' leftRightSwitch' : ''
          } `}
        >
          <div className="collageBlock column is-6">
            <div
              className={`colWrapper columns layout-${lefties} variation-${leftLayout}
              ${isMobile ? 'mobile' : 'desktop'}`}
              style={{ height: isMobile ? blockHeight_m : blockHeight_d }}
            >
              {leftBlock &&
                leftBlock.map((block, idx) => (
                  <CollageItem
                    key={`img-${idx}of${lefties}`}
                    block={block}
                    className={`column is--${idx} img-${idx}of${lefties}`}
                  />
                ))}
            </div>
          </div>

          <div className="collageBlock column is-6">
            <div
              className={`colWrapper columns layout-${righties} variation-${rightLayout}
              ${isMobile ? 'mobile' : 'desktop'}`}
              style={{ height: isMobile ? blockHeight_m : blockHeight_d }}
            >
              {rightBlock &&
                rightBlock.map((block, idx) => (
                  <CollageItem
                    key={`img-${idx}of${righties}`}
                    block={block}
                    className={`column is--${idx} img-${idx}of${righties}`}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
