import { Builder } from '@builder.io/react'
import { IconographyWithText } from './IconographyWithText'

Builder.registerComponent(IconographyWithText, {
  name: 'Iconography with text',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'icons',
      type: 'list',
      subFields: [
        {
          name: 'icon',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true
        },
        {
          name: 'heading',
          type: 'string',
          defaultValue: 'Icon heading'
        }
      ],
      onChange: (options) => {
        if (options.get('icons').length > 8) {
          options.set('icons', options.get('icons').slice(0, 8))
          alert('maximum items is 8')
        }
      }
    },
    {
      name: 'heading',
      type: 'string',
      defaultValue: 'Your heading here'
    },
    {
      name: 'description',
      type: 'longText'
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
    }
  ],
})
