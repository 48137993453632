import { builder } from '@builder.io/react'
// a set of widgets you can use in the editor, optional.
import '@builder.io/widgets'
/**
 * Import all custom components so you can use in the builder.io editor
 * https://www.builder.io/c/docs/custom-react-components
 */
import './components/Hero/Hero.builder'
import './components/Description/Description.builder'
import './components/FeaturedClients/FeaturedClients.builder'
import './components/ClientLogos/ClientLogos.builder'
import './components/Services/Services.builder'
import './components/Partners/Partners.builder'
import './components/Difference/Difference.builder'
import './components/Contact/Contact.builder'
import './components/VideoCard/video-card'
import './components/Hero2/Hero2.builder' 
import './components/MultiColumnWithText/MultiColTxt.builder'
import './components/Collage/Collage.builder'
import './components/Testimonial/Testimonial.builder' 
import './components/ServicesBox/ServicesBox.builder'
import './components/LogoCarousel/LogoCarousel.builder'
import './components/ImageWithText/ImageWithText.builder'
import './components/MulticolumnIconography/MulticolumnIconography.builder'
import './components/ImageWithTextCarousel/ImageWithTextCarousel.builder'
import './components/MulticolumnText/MulticolumnText.builder'
import './components/PartnerLogoCarousel/PartnerLogoCarousel.builder'
import './components/ImageWithText2/ImageWithText2.builder'
import './components/IconographyWithText/IconographyWithText.builder'
import './components/RelatedPages/RelatedPages.builder'

import config from './config'
builder.init(config.builderAPIKey)
