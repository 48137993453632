import React, { useEffect } from 'react';
import { Image } from '@builder.io/react';
import IO from '../Io';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useMediaQuery } from 'react-responsive';

export const ImageWithTextCarousel = ({ heading, slides }) => {
  useEffect(() => {
    new IO();
  }, []);

  const mobileOptions = {
    breakpoints: {
      769: {
        gap: '0.5rem'
      }
    }
  }

  const isDesktop = useMediaQuery({ minWidth: 768 });

  function buildStyles({ logoWidth, logoWidthMobile }) {
    if(!logoWidth || !logoWidthMobile) return;

    return {
      width: isDesktop ? logoWidth : logoWidthMobile
    }
  }

  return (
    <section className="section image-with-text image-with-text--carousel">
      <div className="container">
        <div className="columns">
          <div className="column is-12" data-animation-scroll="fade-in-up" data-animation-delay="300">
            <h2 className="image-with-text--carousel__heading">{heading}</h2>
            <Splide options={{ arrows: false, pagination: false, padding: { right: '1.5rem', left: '1.5rem' }, gap: '2rem', ...mobileOptions }}>
              {slides?.map((slide, idx) => (
                <SplideSlide key={slide.heading + idx || idx}>
                  <div className="content">
                    <article className="image-with-text__item">
                      <div className="columns is-multiline image-with-text__item--content">
                        <div className="column is-6 wrapper__content">
                          {slide.logo && 
                            <div className="wrapper__logo-container" style={buildStyles(slide)}>
                              <Image image={slide.logo} className={`wrapper__logo ${slide.logoWidth && slide.logoWidthMobile ? 'wrapper__logo--fill' : ''}`} altText={slide.logoName} />
                            </div>
                          }
                          {slide.subheading && <p className="wrapper__subheading">{slide.subheading}</p>}
                          {slide.heading && <h2>{slide.heading}</h2>}
                          {slide.description && <p className="wrapper__description">{slide.description}</p>}
                          {slide.tags && <ul className="taglist">
                            {slide.tags.map(({ tag }) => (
                              <li key={tag}>{tag}</li>
                            ))}
                          </ul>}
                        </div>
                        <div className="column is-6 wrapper__image">
                          <Image image={slide.image} altText={slide.heading} />
                        </div>
                      </div>
                    </article>
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </div>
    </section>
  );
};
