

import { Builder } from '@builder.io/react'
import { ServicesBox } from './ServicesBox'

Builder.registerComponent(ServicesBox, {
  name: 'ServicesBox',
  image: 'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  
  inputs: [
    {
      name: 'leftRightSwitch',
      friendlyName: 'Left/Right Switch',
      type: 'boolean',
      defaultValue: true,  
    },
 
    {
      name: 'left_block_title',
      friendlyName: "Text block title",
      type: 'string', 
      defaultValue: "THE CONCLUSION"      
    }, 
    {
      name: 'left_block_body',
      friendlyName: "Text block body",
      type: 'longText', 
      defaultValue: "After working in the eCommerce industry for years and getting frustrated with agencies, we built a company that aims to offer you strategic direction and services from people who have been in your shoes."      
    },

    {
      name: 'right_block_title',
      friendlyName: "Text block title",
      type: 'string', 
      defaultValue: "SERVICES PROVIDED"      
    }, 
    {
      name: 'right_block_body',
      friendlyName: "Text block body",
      type: 'longText', 
      defaultValue: "Our full-service eCommerce agency allows our clients to select the expert services they need. Taking on every aspect of your online business."      
    }, 

    {
      name: 'buttons',
      type: 'list',
      defaultValue: [
        {
          btn_txt : "CONSULTATION"
        },
        {
          btn_txt : "DEVELOPMENT"
        },
        {
          btn_txt : "QUALITY ASSURANCE"
        }
      ],
      subFields: [
        { name: 'btn_txt', type: 'string', defaultValue: 'Title Here' } 
      ],
      onChange: (options, parent, parentElements) => {
        if (options.get('buttons').length > 4) {
          options.set('buttons', options.get('buttons').slice(0, 4))
          alert('maximum items is 4, delete items to continue')
        }
      }
    }
  ]
})
