import React, { useEffect } from 'react'
import { Parallax } from 'react-parallax'
import Box from '@mui/system/Box'
import IO from '../Io'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const Hero = ({
  image,
  topText,
  title,
  descriptionText,
  ctaText,
  ctaLink,
  ctaColor,
  ctaColor2,
  ctaText2,
  ctaLink2,
  parallaxStrength
}) => {
  useEffect(() => {
    new IO()
  }, [])

  return (
    <>
      <Parallax
        bgImageAlt={title}
        strength={parallaxStrength}
        className="full-width-image margin-top-0 hero"
        data-animation-scroll="fade-in-up"
        bgImage={image}
      >
        <Box component="section" className="hero__content" textAlign="center">
          <header aria-labelledby="hero-title">
            <p
              className="hero__topText"
              role="doc-subtitle"
              data-animation-scroll="fade-in-left"
              data-animation-delay="200"
            >
              {topText}
            </p>
            <h1
              className="hero__heading"
              id="hero-title"
              data-animation-scroll="fade-in-left"
              data-animation-delay="200"
            >
              {title}
            </h1>
            <p
              className="hero__description"
              data-animation-scroll="fade-in-left"
              data-animation-delay="200"
            >
              {descriptionText}
            </p>
            <div className="hero__cta--container">
              <AnchorLink
                className={`hero__cta hero__cta--${ctaColor}`}
                aria-label={`${ctaText}`}
                data-animation-scroll="fade-in-left"
                data-animation-delay="200"
                to={ctaLink || '/#our-work'}
                stripHash={true}
              >
                {ctaText}
              </AnchorLink>
              {ctaLink2 && (
                <AnchorLink
                  className={`hero__cta hero__cta--${ctaColor2}`}
                  aria-label={`${ctaText2}`}
                  data-animation-scroll="fade-in-left"
                  data-animation-delay="200"
                  to={ctaLink2 || '/#our-work'}
                  stripHash={true}
                >
                  {ctaText2}
                </AnchorLink>
              )}
            </div>
          </header>
        </Box>
      </Parallax>
      <div className="hero-bottom-fade"></div>
    </>
  )
}
