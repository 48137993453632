import React, { useEffect } from 'react';
import { Image } from '@builder.io/react';
import IO from '../Io';
import { Splide, SplideSlide } from '@splidejs/react-splide';

export const LogoCarousel = ({ title, logosList, autoplay }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className="section logo-carousel" data-animation-scroll="fade-in-up" data-animation-delay="300">
      <div className="columns">
        <div className="column is-12">
          <h2 className="logo-carousel__title">{title}</h2>
          <Splide options={{ type: 'loop', drag: 'free', arrows: false, pagination: false, autoWidth: true, autoplay: autoplay }}>
            {logosList?.map((logo, idx) => (
              <SplideSlide
                key={logo.name || idx}
              >
                <Image image={logo.logo} altText={`${logo.name} Logo`} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </section>
  );
};
