import React, { useEffect } from 'react';
import { Image } from '@builder.io/react';
import IO from '../Io';
import background from '../../img/multicolumn_bg.png';

export const RelatedPages = ({ heading, description, pages }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className="section related-pages"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: '500%',
        backgroundPosition: 'center 75%',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="container">
        {heading && <h2 className="related-pages__heading">{heading}</h2>}
        {description && <p className="related-pages__description">{description}</p>}
        <div className="columns related-pages__columns">
          {pages?.map((page, idx) => (
            <div key={page.heading + idx || idx} className="column is-4">
              <div className="content">
                <article className="related-pages__item" data-animation-scroll="fade-in-up" data-animation-delay="300">
                  <div className="related-pages__item--content">
                    <div className="wrapper__image">
                      {page.image && <Image image={page.image} altText={page.heading} />}
                    </div>
                    <div className="wrapper__content">
                      {page.heading && <h3 className="wrapper__heading">{page.heading}</h3>}
                      {page.description && <p className="wrapper__description">{page.description}</p>}
                      {page.ctaText && <a className="wrapper__btn" href={page.ctaUrl}>{page.ctaText}</a>}
                    </div>
                  </div>
                </article>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
