import React, { useEffect } from 'react'
import IO from '../Io'

export const Description = (props) => {
  const { description, spinnerTitle, spinnerText } = props

  useEffect(() => {
    new IO()
  }, [])

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-12">
              <div className="content">
                <div className="columns">
                  <div className="column is-6">
                    <div className="home__description">
                      <h3>{description}</h3>
                    </div>
                  </div>
                  <div className="column is-6 home__dyode-def--container">
                    <div className="home__dyode-def">
                      <h2 className="title">{spinnerTitle}</h2>
                      <div className="circle">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="300px"
                          height="300px"
                          viewBox="0 0 300 300"
                          enableBackground="new 0 0 300 300"
                          xmlSpace="preserve"
                        >
                          <defs>
                            <path
                              id="circlePath"
                              d=" M 150, 150 m -140, 0 a 140,140 0 0,1 280,0 a 140,140 0 0,1 -280,0 "
                            />
                          </defs>
                          <circle cx="150" cy="100" r="75" fill="none" />
                          <g>
                            <use xlinkHref="#circlePath" fill="none" />
                            <text fill="#D2D4D9">
                              <textPath xlinkHref="#circlePath">
                                {spinnerText}
                              </textPath>
                            </text>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
