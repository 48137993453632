import React, { useEffect } from 'react'
import { Image } from '@builder.io/react'
import IO from '../Io'

export const Partners = (props) => {
  const { topText, title, description, partnerList } = props
  useEffect(() => {
    new IO()
  }, [])

  return (
    <section className="section partners" id="partners" aria-label="Our Partners">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="content">
              <p className="partners__topText" data-animation-scroll="fade-in-up" data-animation-delay="300">
                {topText}
              </p>
              <h3 className="partners__heading" data-animation-scroll="fade-in-up" data-animation-delay="300">
                {title}
              </h3>
              <p className="partners__description" data-animation-scroll="fade-in-up" data-animation-delay="300">
                {description}
              </p>
              <div className="columns is-multiline partners__list">
                {partnerList &&
                  partnerList.map((partner) => (
                    <article
                      key={partner.name}
                      data-animation-scroll="fade-in-up" data-animation-delay="300"
                      className="partners__item column is-6-mobile is-2"
                    >
                      <div className="partner__content">
                        <div className="partner__logo">
                          <Image image={partner.logo} altText={`${partner.name} Logo`} />
                        </div>
                      </div>
                    </article>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
