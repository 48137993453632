import React, { useEffect } from 'react';
import { Image } from '@builder.io/react';
import IO from '../Io';

export const IconographyWithText = ({ icons, heading, description, image }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className="section iconography-with-text">
      <div className="container">
        <div className="columns">
          <div className="column is-6">
            <div className="iconography-with-text__columns" data-animation-scroll="fade-in-up" data-animation-delay="300">
              {icons?.map((item, idx) => (
                <div key={item.heading + idx || idx} className="iconography-with-text__column">
                  {item.icon && 
                    <Image image={item.icon} altText={item.heading} />
                  }
                  {item.heading && 
                    <h3>{item.heading}</h3>
                  }
                  {item.description && 
                    <p>{item.description}</p>
                  }
                </div>
              ))}            
            </div>
          </div>
          <div className="iconography-with-text__content column is-6" data-animation-scroll="fade-in-up" data-animation-delay="300">
            <div>
              {heading && 
                <h3>{heading}</h3>
              }
              {description && 
                <p>{description}</p>
              }
            </div>
            {image && 
              <Image image={image} altText={heading} />
            }
          </div>
        </div>
      </div>
    </section>
  );
};
