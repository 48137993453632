import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'

export default function RootLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="theme-color" content="#f8f8f8" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      </Helmet>
      <Layout>{props.children}</Layout>
    </React.Fragment>
  )
}
