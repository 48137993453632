import { Builder } from '@builder.io/react'
import { MulticolumnText } from './MulticolumnText'

Builder.registerComponent(MulticolumnText, {
  name: 'Multicolumn Text',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      defaultValue: 'Your heading here'
    },
    {
      name: 'subheading',
      type: 'string',
      defaultValue: 'Our approach'
    },
    {
      name: 'description',
      type: 'longText'
    },
    {
      name: 'columns',
      type: 'list',
      subFields: [
        {
          name: 'heading',
          type: 'string',
          defaultValue: 'Your heading here'
        },
        {
          name: 'subheading',
          type: 'string',
          defaultValue: 'Your subheading here'
        },
        {
          name: 'description',
          type: 'longText'
        },
      ],
      onChange: (options) => {
        if (options.get('columns').length > 5) {
          options.set('columns', options.get('columns').slice(0, 5))
          alert('maximum items is 5')
        }
      }
    }
  ]
})
