import React, { useEffect } from 'react';
import { Image } from '@builder.io/react';
import IO from '../Io';

export const MulticolumnIconography = ({ column, shiftUpOnDesktop }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className={`section multicolumn-iconography${ shiftUpOnDesktop ? ' multicolumn-iconography--attached' : ''}`}>
      <div className="container">
        <div className="columns multicolumn-iconography__columns" data-animation-scroll="fade-in-up" data-animation-delay="300">
          {column?.map((item, idx) => (
            <div key={item.heading + idx || idx} className="column multicolumn-iconography__column">
              {item.icon && 
                <Image image={item.icon} altText={item.heading} />
              }
              {item.heading && 
                <h3>{item.heading}</h3>
              }
              {item.description && 
                <p>{item.description}</p>
              }
            </div>
          ))}            
        </div>
      </div>
    </section>
  );
};
