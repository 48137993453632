import React, { useEffect } from 'react'
import IO from '../Io'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const ContactForm = () => (
  <div
    className="column is-6 is-12-mobile contact-form"
    data-animation-scroll="fade-in-left"
    data-animation-delay="300"
  >
    <AnchorLink
      className={'navbar-item work-with-us'}
      to="/contact"
      content="Work with us"
      stripHash={true}
    >
      Work with us
    </AnchorLink>
  </div>
)

const ContactContent = ({ title, description, ideasTitle, ideas }) => (
  <div
    className="column is-6 is-12-mobile"
    data-animation-scroll="fade-in-right"
    data-animation-delay="300"
  >
    <h3>{title}</h3>
    <p dangerouslySetInnerHTML={{ __html: description }}></p>
  </div>
)

export const Contact = ({
  title,
  description,
  ideasTitle,
  ideas,
  mobileBg,
  desktopBg
}) => {
  useEffect(() => {
    new IO()

    const BREAKPOINT = 768
    const contactSection = document.getElementById('contact')
    const backgroundImage =
      window.innerWidth <= BREAKPOINT ? mobileBg : desktopBg
    contactSection.style.setProperty(
      '--background-image',
      `url(${backgroundImage})`
    )

    const handleResize = () => {
      const backgroundImage =
        window.innerWidth <= BREAKPOINT ? mobileBg : desktopBg
      contactSection.style.setProperty(
        '--background-image',
        `url(${backgroundImage})`
      )
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [mobileBg, desktopBg])

  return (
    <section className="contact" id="contact" aria-label="Contact Us">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="content">
              <div className="columns is-multiline contact__content">
                <ContactContent
                  title={title}
                  description={description}
                  ideasTitle={ideasTitle}
                  ideas={ideas}
                />
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-bottom-fade"></div>
    </section>
  )
}
