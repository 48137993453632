import React, { useEffect } from 'react';
import { Image } from '@builder.io/react';
import IO from '../Io';

export const ImageWithText = ({ heading, subheading, description, image, tags, reverseDesktopLayout }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className="section image-with-text">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="content">
              <article className="image-with-text__item" data-animation-scroll="fade-in-up" data-animation-delay="300">
                <div className={`columns is-multiline image-with-text__item--content${reverseDesktopLayout ? ' image-with-text--reversed' : ''}`}>
                  <div className="column is-6 wrapper__content">
                    {subheading && <p className="wrapper__subheading">{subheading}</p>}
                    {heading && <h2>{heading}</h2>}
                    {description && <p className="wrapper__description">{description}</p>}
                    {tags && <ul className="taglist">
                      {tags.map(({ tag }) => (
                        <li key={tag}>{tag}</li>
                      ))}
                    </ul>}
                  </div>
                  <div className="column is-6 wrapper__image">
                    <Image image={image} altText={heading} />
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
