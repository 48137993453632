

import { Builder } from '@builder.io/react'
import { Collage } from './Collage'

Builder.registerComponent(Collage, {
  name: 'Collage',
  image: "https://cdn.builder.io/api/v1/image/assets%2F9b05a53a9463408aa0ffa696febd3688%2F65d193f072894096b0e1c5dc5b08078c",
  
  inputs: [
    {
      name: 'sec_padding_desk',
      friendlyName: "Section Padding (desktop)",
      type: 'string',
      helperText: "default padding: 0rem 1.5rem",
      defaultValue: "0rem 1.5rem"      
    }, 
    {
      name: 'sec_padding_mobile',
      friendlyName: "Section Padding (mobile)",
      type: 'string',
      helperText: "default padding: 0rem 0.5rem, or enter a number with a valid unit (px vh em)",
      defaultValue: "0rem 0.5rem"      
    }, 
    {
      name: 'leftRightSwitch',
      friendlyName: 'Left/Right Switch',
      type: 'boolean',
      defaultValue: true, 
    },
    {
      name: 'blockHeight_desktop',
      friendlyName: "Block Height (Desktop)",
      type: 'string',
      helperText: "default 450px, or enter a number with a valid unit (px vh em)",
      defaultValue: "450px"    
    }, 
    {
      name: 'blockHeight_mobile',
      friendlyName: "Block Height (Mobile)",
      type: 'string',
      defaultValue: "450px"     
    }, 
    
    {
      name: 'leftBlock',
      friendlyName: "Left Image",
      helperText: "Maximum 3 images",
      type: 'list',
      defaultValue:[ 
        {
          image: "https://cdn.builder.io/api/v1/image/assets%2F9b05a53a9463408aa0ffa696febd3688%2Ff4f670cd289b46a09708f60f47db9042",
          alt: 'img1'
        },     
      ],
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F9b05a53a9463408aa0ffa696febd3688%2Ff4f670cd289b46a09708f60f47db9042"
        },
        {
          name: 'alt',
          type: 'string',
          defaultValue: 'left collage image'
        },
      ],     
      onChange: (options, parent, parentElements) => {
        if (options.get('leftBlock').length > 3) {
          options.set('leftBlock', options.get('leftBlock').slice(0, 3))
          alert('maximum items is 3, delete items to continue')
        }
      }
    },
    {
      name: 'leftBlockLayout',
      friendlyName: "Left Block layout",
      type: 'string',
      required: true,
      enum: ["1","2","3","4"],
      defaultValue: "1"    
    }, 
    {
      name: 'rightBlock',
      friendlyName: "Right Image",
      helperText: "Maximum 3 images",
      type: 'list',
      defaultValue:[ 
        {
          image: "https://cdn.builder.io/api/v1/image/assets%2F9b05a53a9463408aa0ffa696febd3688%2F893db9449aad421dae80c8648d9e6887",
          alt: 'img1'
        },  
        {
          image: "https://cdn.builder.io/api/v1/image/assets%2F9b05a53a9463408aa0ffa696febd3688%2F32c24f5ad70141a9a82c16e48f89efe2",
          alt: 'img2'
        }, 
        {
          image: "https://cdn.builder.io/api/v1/image/assets%2F9b05a53a9463408aa0ffa696febd3688%2F9b981f67b4de44328cc83d8982993718",
          alt: 'img3'
        },    
      ],
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          defaultValue:"https://cdn.builder.io/api/v1/image/assets%2F9b05a53a9463408aa0ffa696febd3688%2F893db9449aad421dae80c8648d9e6887"
        },
        {
          name: 'alt',
          type: 'string',
          defaultValue: 'right collage image'
        },
      ],
      onChange: (options) => {
        if (options.get('rightBlock').length > 3) {
          options.set('rightBlock', options.get('rightBlock').slice(0, 3))
          alert('maximum items is 3, delete items to continue')
        }
      }
    },
    {
      name: 'rightBlockLayout', 
      friendlyName: "Right Block layout",
      type: 'string',
      required: true,
      enum: ["1","2","3","4"],
      defaultValue: "1"    
    }
  ]
})
