
import React, { useEffect, useRef } from 'react'
import { Image } from '@builder.io/react'
import { Link } from 'gatsby'
import IO from '../Io'

export const Services = (props) => {
  const { title, subTitle, description, servicesList, backgroundImage } = props
  const servicesRef = useRef(null)

  useEffect(() => {
    new IO()
  }, [])

  return (
    <section
      className="section services"
      id="services"
      ref={servicesRef}
      aria-label="Our Services"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="services-top-fade"></div>
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="content">
              <article aria-label="About Our Services">
                <p className="services__subtitle">{subTitle}</p>
                <h3
                  data-animation-scroll="fade-in-up"
                  data-animation-delay="300"
                >
                  {title}
                </h3>
                <p className="services__description">{description}</p>
              </article>
              <ul className="services__list">
                {servicesList &&
                  servicesList.map((service, index) => (
                    <li 
                      key={`${service.title}-${index}`}
                      className="services__item"
                      data-animation-scroll="fade-in-up"
                      data-animation-delay="300"
                    >
                      <div className="services__item--content">
                        {service.icon && (
                          <div className="services__icon">
                            <Image
                              image={service.icon}
                              altText={`${service.title} Icon`}
                            />
                          </div>
                        )}
                        <h4>{service.title}</h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: service.description
                          }}
                        ></div>
                        {service.ctaLink && (
                          <Link
                            className="services__item--link"
                            to={service.ctaLink}
                          >
                            {service.ctaText}
                          </Link>
                        )}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="services-bottom-fade"></div>
    </section>
  )
}
