import React, { useEffect } from 'react';
import { Image } from '@builder.io/react';
import IO from '../Io';

const ClientItem = ({ client }) => (
  <article className="featured-clients__item" data-animation-scroll="fade-in-up" data-animation-delay="300">
    <div className="columns is-multiline featured-clients__item--content">
      <div className="column is-6 client__content">
        <div className="client__logo">
          <Image image={client.logo} altText={`${client.name} Logo`} />
        </div>
        <p className="client__description">{client.description}</p>
        <ul className="taglist">
          {client.tags.map(({ tag }) => (
            <li key={tag}>{tag}</li>
          ))}
        </ul>
      </div>
      <div className="column is-6 client__image">
        <Image image={client.image} altText={`Image of ${client.name}`} />
      </div>
    </div>
  </article>
);

export const FeaturedClients = ({ title, clientList }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className="section featured-clients" id="our-work" aria-label="Featured Clients">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="content">
              {title && <h2 data-animation-scroll="fade-in-up" data-animation-delay="300">{title}</h2>}
              {clientList?.map(client => (
                <ClientItem key={client.name} client={client} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
