import React, { useEffect, useRef, useState } from 'react'
import { Parallax } from 'react-parallax'
import { Box } from '@mui/material'
import IO from '../Io'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const Hero2 = (props) => {
  const {
    sec_padding,
    sec_height_desktop,
    sec_height_mobile,
    full_width,
    image_fit,
    image,
    imageMobile,
    topText,
    title,
    descriptionText,
    ctaText,
    parallaxStrength,
    video,
    botton_fade
  } = props

  useEffect(() => {
    new IO(0)
  }, [])

  // remove the original builder wrapper, but copy its attributes to custom "parallaxWrapper"  in order for it to be selectable in builder visual editor
  const ref = useRef()
  const [isMobile, setIsMobile] = useState(false)
  const [secPaddingDesk, setSecPaddingDesk] = useState('')

  useEffect(() => {
    /* for mobile styles */
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)

    if (ref.current) {
      Object.entries(props.attributes || {}).forEach(
        ([attributeName, attributeValue]) => {
          if (attributeName !== 'className' && attributeName !== 'style') {
            ref.current.setAttribute(attributeName, attributeValue.toString())
          }
        }
      )

      if (sec_padding && sec_padding.trim() !== '') {
        setSecPaddingDesk(sec_padding)
      }
    }

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize)
  }, [props.attributes, secPaddingDesk]) // Run the effect only when attributes change

  let secHeightDesktop = ''
  let secHeightMobile = ''
  // set section height
  if (sec_height_desktop && sec_height_desktop.trim() !== '') {
    secHeightDesktop = sec_height_desktop
  }
  if (sec_height_mobile && sec_height_mobile.trim() !== '') {
    secHeightMobile = sec_height_mobile
  }

  return (
    <>
      <div
        className="parallaxWrapper"
        ref={ref}
        style={secPaddingDesk ? { padding: secPaddingDesk } : {}}
      >
        <div
          className={`${full_width ? '' : ' container '} parallaxWrapperInner`}
          style={{ height: isMobile ? secHeightMobile : secHeightDesktop }}
        >
          <Parallax
            bgImageAlt={title}
            strength={parallaxStrength}
            className={`full-width-image margin-top-0 hero hero2 ${
              video ? ' have_video' : ''
            } ${image_fit ? image_fit : ' cover '}`}
            data-animation-scroll="fade-in-up"
            bgImage={video ? '' : isMobile ? imageMobile : image}
            style={{ height: isMobile ? secHeightMobile : secHeightDesktop }}
          >
            {video && (
              <video
                className={`video`}
                style={{
                  height: isMobile ? secHeightMobile : secHeightDesktop
                }}
                // controls
                autoPlay
                muted
                loop
                src={video}
              ></video>
            )}

            <Box
              component="section"
              className="hero2__content"
              textAlign="center"
            >
              <header aria-labelledby="hero-title">
                {topText && (
                  <p
                    className="hero2__topText"
                    role="doc-subtitle"
                    data-animation-scroll="fade-in-left"
                    data-animation-delay="200"
                  >
                    {topText}
                  </p>
                )}
                <h1
                  className="hero2__heading"
                  id="hero-title"
                  data-animation-scroll="fade-in-left"
                  data-animation-delay="200"
                >
                  {title}
                </h1>

                {descriptionText && (
                  <p
                    className="hero2__description"
                    data-animation-scroll="fade-in-left"
                    data-animation-delay="200"
                  >
                    {descriptionText}
                  </p>
                )}
                {ctaText && (
                  <AnchorLink
                    className="hero2__cta"
                    aria-label={`${ctaText}`}
                    data-animation-scroll="fade-in-left"
                    data-animation-delay="200"
                    to="/#our-work"
                    content="Our Work"
                    stripHash={true}
                  >
                    {ctaText}
                  </AnchorLink>
                )}
              </header>
            </Box>
          </Parallax>
        </div>
        {botton_fade && <div className="hero-bottom-fade"></div>}
      </div>
    </>
  )
}
