import React, { useEffect } from 'react';
import { Image } from '@builder.io/react';
import IO from '../Io';
import { Splide, SplideSlide } from '@splidejs/react-splide';

export const PartnerLogoCarousel = ({ title, subheading, logos, autoplay }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className="section partner-logo-carousel" data-animation-scroll="fade-in-up" data-animation-delay="300">
      <div className="container">
        <div className="columns partner-logo-carousel__columns">
          <div className="column is-4">
            {subheading && <p className="partner-logo-carousel__subheading">{subheading}</p>}
            <h2 className="partner-logo-carousel__title">{title}</h2>
          </div>
          <div className="column is-8">
            <Splide options={{ type: 'loop', drag: 'free', arrows: false, pagination: false, autoWidth: true, autoplay: autoplay, clones: 5 }}>
              {logos?.map((logo, idx) => (
                <SplideSlide
                  key={logo.name || idx}
                >
                  <Image image={logo.logo} altText={`${logo.name} Logo`} />
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </div>
    </section>
  );
};
