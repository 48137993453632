import { Builder } from '@builder.io/react'
import { ClientLogos } from './ClientLogos'

Builder.registerComponent(ClientLogos, {
  name: 'More Clients',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'MORE CLIENTS.'
    },
    {
      name: 'clientList',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string' },
        {
          name: 'logo',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true
        }
      ]
    }
  ]
})
