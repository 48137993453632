import React, { useEffect } from 'react';
import { Image } from '@builder.io/react';
import IO from '../Io';

export const ClientLogos = ({ title, clientList }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className="section clients" aria-label="More of Our Clients">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="content">
              <h2 data-animation-scroll="fade-in-up" data-animation-delay="300">{title}</h2>
              <div className="columns is-multiline clients__list">
                {clientList.map((client) => (
                  <article
                    key={client.name}
                    className="clients__item column is-6-mobile is-2"
                    data-animation-scroll="fade-in-up" data-animation-delay="300"
                  >
                    <figure className="client__content">
                      <div className="client__logo">
                        <Image image={client.logo} altText={`${client.name} Logo`} />
                      </div>
                      <figcaption>{client.name}</figcaption>
                    </figure>
                  </article>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
