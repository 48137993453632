import { Builder } from '@builder.io/react'
import { Hero2 } from './Hero2'

Builder.registerComponent(Hero2, {
  name: 'Hero2',
  noWrap: true, // remove the builder block wraper
  image:
    'https://tabler-icons.io/static/tabler-icons/icons-png/brand-youtube.png',
  inputs: [
    {
      name: 'topText',
      type: 'string',
      defaultValue: 'Your Text Here'
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Your Title Here'
    },
    {
      name: 'descriptionText',
      type: 'string',
      defaultValue: 'Your Text Here'
    },
    {
      name: 'ctaText',
      type: 'string',
      defaultValue: 'Your Text Here'
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
    },
    {
      name: 'imageMobile',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
    },
    {
      name: 'image_fit',
      friendlyName: 'Image: cover or contain',
      type: 'string',
      enum: ['cover', 'contain'],
      defaultValue: 'cover'
    },
    {
      name: 'video',
      type: 'file',
      allowedFileTypes: ['mp4'],
      defaultValue:
        'https://cdn.shopify.com/videos/c/o/v/da057987ccb84b19a02bc20f9588a6c8.mp4',
      helperText: 'Show video over image if both present.'
    },
    {
      name: 'botton_fade',
      friendlyName: 'Bottom Fade',
      type: 'boolean',
      defaultValue: true
    },
    // `advanced: true` hides this option under the "show advanced" toggle
    {
      name: 'parallaxStrength',
      type: 'number',
      advanced: true,
      defaultValue: 400,
      helperText: 'To disable parallax effect, enter 0'
    },
    {
      name: 'full_width',
      friendlyName: 'Full Width',
      type: 'boolean',
      advanced: true,
      defaultValue: true
    },
    {
      name: 'sec_padding',
      friendlyName: 'Section Padding',
      type: 'string',
      advanced: true,
      helperText:
        'default padding: 0px 0px 0px 0px, or enter a number with a valid unit (px vh em)',
      defaultValue: '0px 0px 0px 0px'
    },

    {
      name: 'sec_height_desktop',
      friendlyName: 'Section Height (desktop)',
      type: 'string',
      advanced: true,
      defaultValue: ''
    },
    {
      name: 'sec_height_mobile',
      friendlyName: 'Section Height (mobile)',
      type: 'string',
      advanced: true,
      helperText:
        "default is ''. or enter a number with a valid unit (px vh em), eg: 400px",
      defaultValue: ''
    }
  ]
})
