import * as React from 'react'
import '../builder-settings'

function PageLayout({ children }) {
  return (
    <>
      <div>
        <div>{children}</div>
      </div>
    </>
  )
}

export default PageLayout
