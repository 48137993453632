

import { Builder } from '@builder.io/react'
import { Testimonial } from './Testimonial'

Builder.registerComponent(Testimonial, {
  name: 'Testimonial',
  image: 'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  
  inputs: [
    {
      name: 'leftRightSwitch',
      friendlyName: 'Left/Right Switch',
      type: 'boolean',
      defaultValue: true,  
    },
    {
      name: 'left_block',
      friendlyName: "Testimonial copy",
      type: 'longText', 
      defaultValue: "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos."      
    }, 
    {
      name: 'right_block_title',
      friendlyName: "Text block title",
      type: 'string', 
      defaultValue: "DEVELOPING RELATIONSHIPS"      
    }, 
    {
      name: 'right_block_body',
      friendlyName: "Text block body",
      type: 'longText', 
      defaultValue: "After working in the eCommerce industry for years and getting frustrated with agencies, we built a company that aims to offer you strategic direction and services from people who have been in your shoes."      
    }
  ]
})
