import { Builder } from '@builder.io/react'
import { MultiColTxt } from './MultiColTxt'

Builder.registerComponent(MultiColTxt, {
  name: 'Multicolumn with Text',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'txtBlockTitle',
      friendlyName: 'Text block title',
      type: 'string',
      defaultValue: 'WORKING BETTER TOGETHER'
    },
    {
      name: 'txtBlockBody',
      friendlyName: 'Text block body',
      type: 'longText',
      defaultValue:
        'After working in the eCommerce industry for years and getting frustrated with agencies, we built a company that aims to offer you strategic direction and services from people who have been in your shoes.'
    },
    {
      name: 'txtBlockLeft',
      friendlyName: 'Text block on left',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'multiColumns',
      type: 'list',
      defaultValue: [
        {
          header: '10%',
          body: 'Statistics Goes Here'
        },
        {
          header: '15%',
          body: 'Statistics Goes Here'
        },
        {
          header: '69%',
          body: 'Statistics Goes Here'
        }
      ],
      subFields: [
        { name: 'header', type: 'string', defaultValue: '10%' },
        { name: 'body', type: 'longText', defaultValue: 'Statistics Goes Here' }
      ]
    }
  ]
})
