import { Builder } from '@builder.io/react'
import { RelatedPages } from './RelatedPages'

Builder.registerComponent(RelatedPages, {
  name: 'Related Pages',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      defaultValue: 'More services'
    },
    {
      name: 'description',
      type: 'longText'
    },
    {
      name: 'pages',
      type: 'list',
      subFields: [
        {
          name: 'heading',
          type: 'string',
          defaultValue: 'Your heading here'
        },
        { name: 'description', type: 'longText' },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
        },
        {
          name: 'ctaText',
          type: 'string',
          defaultValue: 'Button text'
        },
        {
          name: 'ctaUrl',
          type: 'url'
        }
      ],
      onChange: (options) => {
        if (options.get('pages').length > 3) {
          options.set('pages', options.get('pages').slice(0, 3))
          alert('maximum items is 3')
        }
      }
    }
  ]
})
