 
import React, { useEffect } from 'react';
import IO from '../Io';

export const Testimonial = ({left_block, right_block_title , right_block_body, leftRightSwitch  }) => {
  useEffect(() => {
    new IO();
  }, []);
   
  return (
    <section className={`section Testimonial`} aria-label={` Testimonial `}  >
      <div className="container">
        <div className={`section_columns columns ${leftRightSwitch ? " leftRightSwitch" : ""} `}>  
          
          <div className="left_block column is-6">  
            <blockquote>
              {left_block}
            </blockquote>
          </div> 
          <div className="right_block column is-6">  
            <div className='txt_container'>
              <div className="title">{ right_block_title }</div>
              <div className="body">{ right_block_body }</div>
            </div>
          </div>            

        </div>
      </div>
    </section>
  );
};
