import { Builder } from '@builder.io/react'
import { Hero } from './Hero'

Builder.registerComponent(Hero, {
  name: 'Hero',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'topText',
      type: 'string',
      defaultValue: 'Your Text Here'
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Your Title Here'
    },
    {
      name: 'descriptionText',
      type: 'string',
      defaultValue: 'Your Text Here'
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
    },
    {
      name: 'ctaText',
      type: 'string',
      defaultValue: 'Your Text Here'
    },
    {
      name: 'ctaLink',
      type: 'url'
    },
    {
      name: 'ctaColor',
      type: 'string',
      enum: ['primary', 'secondary', 'outline'],
      defaultValue: 'primary'
    },
    {
      name: 'ctaText2',
      type: 'string',
      defaultValue: 'Your Text Here'
    },
    {
      name: 'ctaLink2',
      type: 'url'
    },
    {
      name: 'ctaColor2',
      type: 'string',
      enum: ['primary', 'secondary', 'outline'],
      defaultValue: 'primary'
    },
    // `advanced: true` hides this option under the "show advanced" toggle
    {
      name: 'parallaxStrength',
      type: 'number',
      advanced: true,
      defaultValue: 400,
      helperText: 'To disable parallax effect, enter 0'
    }
  ]
})
