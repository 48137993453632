import { Builder } from '@builder.io/react'
import { ImageWithText } from './ImageWithText'

Builder.registerComponent(ImageWithText, {
  name: 'Image With Text',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      defaultValue: 'Develop with us'
    },
    {
      name: 'subheading',
      type: 'string',
      defaultValue: 'Unparalleled team dynamics'
    },
    { name: 'description', type: 'longText' },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true
    },
    {
      name: 'tags',
      type: 'list',
      subFields: [{ name: 'tag', type: 'sting' }]
    },
    {
      name: 'reverseDesktopLayout',
      type: 'boolean'
    }
  ]
})
