import { Builder } from '@builder.io/react';
import { Contact } from './Contact';

Builder.registerComponent(Contact, {
  name: 'Contact',
  image: 'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Contact Us'
    },
    {
      name: 'description',
      type: 'richText'
    },
    {
      name: 'ideasTitle',
      type: 'string',
      defaultValue: 'MESSAGE IDEAS:'
    },
    {
      name: 'ideas',
      type: 'richText'
    },
    {
      name: 'mobileBg',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      description: 'First Mobile Background Image',
    },
    {
      name: 'desktopBg',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      description: 'First Desktop Background Image',
    },
    {
      name: 'partnerList',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string' },
        {
          name: 'logo',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true
        }
      ]
    }
  ]
});
