 
import React, { useEffect } from 'react';
import IO from '../Io';

const ColumnItem = ({ column, className }) => (
  <article className={`multiColTxt__item ${className}`} data-animation-scroll="fade-in-up" data-animation-delay="300">
    <div className="multiColTxt__item-top">
      { column.header }
    </div>
    <div className="multiColTxt__item-bottom">
      { column.body }
    </div>
  </article>
);

export const MultiColTxt = ({ txtBlockTitle, txtBlockBody, multiColumns, txtBlockLeft }) => {
  useEffect(() => {
    new IO();
  }, []);
  
  const numOfCols = (multiColumns) ? 12 / multiColumns.length : 0


  return (
    <section className={`section multiColTxt`} 
      aria-label={`${txtBlockTitle} multiColTxt`}>
      <div className="container">
        <div className={`section_columns columns ${txtBlockLeft ? " txtBlockLeft" : ""} `}>  
          
          <div className="txtBlock multiColTxt_block column is-6">
            <div className="txtBlock_wrapper">

              <h2 className="txtBlock_title">    
                {txtBlockTitle}
              </h2>
              <div className="txtBlock_body">
                {txtBlockBody}
              </div>

            </div>            
          </div>
          
          <div className="colBlock multiColTxt_block column is-6">
            <div className="colWrapper columns">
              {multiColumns && multiColumns.map((column, idx) => (
                <ColumnItem key={`${column.name}-${idx}`} column={column} className={`column is-${numOfCols}`}/>
              ))}
            </div>            
          </div> 

        </div>
      </div>
    </section>
  );
};
