import React, { useEffect } from 'react';
import IO from '../Io';
import background from '../../img/multicolumn_bg.png'

export const MulticolumnText = ({ heading, subheading, description, columns }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className="section multicolumn-text"
      style={{
        backgroundImage: `url(${background})`
      }}
    >
      <div className="container" data-animation-scroll="fade-in-up" data-animation-delay="300">
        <div className="columns">
          <div className="column is-12">
            <div className="multicolumn-text__content">
              {subheading && <p className="multicolumn-text__subheading">{ subheading }</p>}
              {heading && <h2 className="multicolumn-text__heading">{ heading }</h2>}
              {description && <p className="multicolumn-text__copy">{ description }</p>}
            </div>
            <div className="columns multicolumn-text__columns">
              {columns?.map((item, idx) => (
                <div key={item.heading + idx || idx} className="column multicolumn-text__column">
                  {item.subheading && 
                    <p className="multicolumn-text__column__subheading">{item.subheading}</p>
                  }
                  {item.heading && 
                    <h3 className="multicolumn-text__column__heading">{item.heading}</h3>
                  }
                  {item.description && 
                    <p className="multicolumn-text__column__copy">{item.description}</p>
                  }
                </div>
              ))}            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
