export default class IO {
  constructor(threshold = 0.5) {
    this.elements = Array.from(
      document.querySelectorAll(
        '[data-animation-scroll="fade-in-up"], [data-animation-scroll="fade-in"], [data-animation-scroll="fade-in-left"], [data-animation-scroll="fade-in-right"]'
      )
    )
    this.validatedElements = []

    this.options = {
      root: null,
      rootMargin: '100px 0px 100px 0px',
      threshold: [threshold]
    }

    this.elements.forEach((el) => {
      if (el.selector !== null) {
        this.validatedElements.push(el)
      }
    })
    this.observe(this.validatedElements)
  }

  observe(elements) {
    const observer = new IntersectionObserver(
      this.observeCallback,
      this.options
    )

    elements.forEach((element) => {
      observer.observe(element)
    })
  }

  observeCallback(entries, observer) {
    const threshold = observer.thresholds[0]

    entries.forEach((entry) => {
      if (entry.intersectionRatio >= threshold) {
        if (!entry.target.classList.contains('in-view')) {
          entry.target.dispatchEvent(new Event('in-view'))
        }
        entry.target.classList.add('in-view')
      }
      if (document.body.classList.contains('builder-editing-page')) {
        entry.target.classList.add('in-view')
      }
    })
  }
}
