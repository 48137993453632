import React, { useEffect } from 'react';
import IO from '../Io';

const DifferenceList = ({ items }) => (
  <div className="differences__list">
    {items &&
      items.map((difference) => (
        <article key={difference.title} className="differences__item" data-animation-scroll="fade-in-up" data-animation-delay="300">
          <h4 className="differences__item-content-title">{difference.title}</h4>
        </article>
      ))}
  </div>
);

export const Difference = ({ title, subTitle, description, items }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className="differences" id="differences" aria-label="The Dyode Difference">
      <div className="container">
        <p className="differences__subtitle">
          {subTitle}
        </p>
        <h3 className="differences__title" data-animation-scroll="fade-in-up" data-animation-delay="300">
          {title}
        </h3>
        <div
          className="differences__description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <DifferenceList items={items} />
      </div>
    </section>
  );
};
