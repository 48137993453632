import { Builder } from '@builder.io/react'
import { Services } from './Services'

Builder.registerComponent(Services, {
  name: 'Services',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'OUR Servies'
    },
    {
      name: 'subTitle',
      type: 'string',
      defaultValue: 'What We Do'
    },
    {
      name: 'description',
      type: 'richText',
      defaultValue: 'Desctiption Text'
    },
    {
      name: 'ctaText',
      type: 'string',
      defaultValue: 'Learn More'
    },
    {
      name: 'ctaUrl',
      type: 'url', 
      defaultValue: '/services'
    },
    {
      name: 'servicesList',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string' },
        { name: 'description', type: 'richText' },
        {
          name: 'icon',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
        },
        {
          name: 'ctaLink',
          type: 'string',
          defaultValue: '/'
        },
        {
          name: 'ctaText',
          type: 'string',
          defaultValue: 'Learn More'
        }
      ]
    },
    {
      name: 'backgroundImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      required: false
    }
  ]
})
