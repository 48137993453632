import React from 'react'
import { Link } from 'gatsby'

const Footer = class extends React.Component {
  getCurrentYear = () => {
    return new Date().getFullYear()
  }

  render() {
    return (
      <footer className="footer">
        <div className="content">
          <div className="container">
            <div className="columns">
              <div className="column is-9 is-12-mobile">
                <section className="address">
                  ADDRESS:
                  <a href="http://maps.google.com/?q=1108 W Commonwealth Ave, Fullerton, CA 92833">
                    1108 W Commonwealth Ave, Fullerton, CA 92833
                  </a>
                </section>
                <section className="phone">
                  PHONE:
                  <a href="tel:866-948-5439">866.948.5439</a>
                </section>
                <section className="links">
                  <Link
                    to="/privacy"
                    title="Privacy Policy"
                    content="Privacy Policy"
                  >
                    Privacy Policy
                  </Link>
                </section>
              </div>
              <div className="column is-3 is-12-mobile copy">
                ©{this.getCurrentYear()} Dyode Inc.
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
