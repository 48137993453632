import { Builder } from '@builder.io/react'
import { MulticolumnIconography } from './MulticolumnIconography'

Builder.registerComponent(MulticolumnIconography, {
  name: 'Multicolumn Iconography',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'shiftUpOnDesktop',
      type: 'boolean'
    },
    {
      name: 'column',
      type: 'list',
      subFields: [
        {
          name: 'icon',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true
        },
        {
          name: 'heading',
          type: 'string',
          defaultValue: 'Column heading'
        },
        { 
          name: 'description',
          type: 'longText',
          defaultValue: 'Column description'
        }
      ],
      onChange: (options) => {
        if (options.get('column').length > 4) {
          options.set('column', options.get('column').slice(0, 4))
          alert('maximum items is 4')
        }
      }
    }
  ],
})
