import React, { useEffect } from 'react';
import { Image } from '@builder.io/react';
import IO from '../Io';
import dotSquare from '../../img/dot-square.svg'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const ImageWithText2 = ({ heading, subheading, description, image, ctaText, ctaLink }) => {
  useEffect(() => {
    new IO();
  }, []);

  return (
    <section className="section image-with-text-2">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="content">
              <article className="image-with-text-2__item" data-animation-scroll="fade-in-up" data-animation-delay="300">
                <div className="columns is-multiline image-with-text-2__item--content">
                  <div className="column is-6 wrapper__content">
                    {subheading && <p className="wrapper__subheading">{subheading}</p>}
                    {heading && <h2>{heading}</h2>}
                    {description && <p className="wrapper__description">{description}</p>}
                    {ctaText && ctaLink &&
                      <AnchorLink
                        className="wrapper__btn"
                        aria-label={`${ctaText}`}
                        to={ctaLink}
                        stripHash={true}
                      >
                        {ctaText}
                      </AnchorLink>
                    }
                  </div>
                  <div className="column is-6 wrapper__image">
                    <img className="dot-square" src={dotSquare} alt="" />
                    <Image image={image} altText={heading} />
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
