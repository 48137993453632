import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../scss/all.scss'
import useSiteMetadata from './SiteMetadata'

import dyodeImage from '../img/dyode_image.png'
import favicon16 from '../img/favicon-16x16.png'
import favicon32 from '../img/favicon-32x32.png'
import dyodeLogo from '../img/dyode_logo_png.png'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
        <link rel="icon" type="image/png" href={favicon16} sizes="16x16" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,maximum-scale=5.0"
        />

        <meta name="theme-color" content="#fff" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="https://www.dyode.com" />
        <meta property="og:image" content={dyodeImage} />
        <meta property="og:image:width" content="2500" />
        <meta property="og:image:height" content="1330" />
        <meta property="og:url" content="https://www.dyode.com" />
        <meta property="og:site_name" content="dyode" />
        <meta property="og:type" content="website" />
        <meta name="fb_admins_meta_tag" content="dyodecommerce" />
        <meta
          name="google-site-verification"
          content="PLtGPh26sqixyn-bcSOsNy-ZKIS-o_4Uizw21q9Xd0U"
        />
        <meta
          name="keywords"
          content="analytics, consulting, digital marketing, e-commerce, web development"
        />
        <meta property="fb:admins" content="dyodecommerce" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={dyodeImage} />
        <meta name="logoimage" content={dyodeLogo} />
        <script
          id="vtag-ai-js"
          async
          src="https://r2.leadsy.ai/tag.js"
          data-pid="zgAvYt7uc36QxaMb"
          data-version="062024"
        ></script>
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </>
  )
}

export default TemplateWrapper
