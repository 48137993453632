 
import React, { useEffect } from 'react';
import IO from '../Io';

const BtnItem = ({ btn, className }) => (
  <article className={`btn__item ${className}`} data-animation-scroll="fade-in-up" data-animation-delay="300">
    <div className="btn__item-text">
      { btn.btn_txt }
    </div>
  </article>
);

export const ServicesBox = ({left_block_title, left_block_body, right_block_title , right_block_body, leftRightSwitch, buttons }) => {
  useEffect(() => {
    new IO();
  }, []);
   
  return (
    <section className={`section ServicesBox`} aria-label={` ServicesBox `}  >
      <div className="container">
        <div className={`section_columns columns ${leftRightSwitch ? " leftRightSwitch" : ""} `}>  
          
          <div className="left_block column is-6">  
            <div className='txt_container'>
              <div className="title">{ left_block_title }</div>
              <div className="body">{ left_block_body }</div>
            </div>
          </div> 
          <div className="right_block column is-6">  
            <div className="block_header">{ right_block_title }</div>
            <div className="block_body">

              <div className='txt_container'> 
                <div className="body">{ right_block_body }</div>
              </div>
              <div className="btn_container">
                {buttons && buttons.map((btn, idx) => (
                  <BtnItem key={`${btn.name}-${idx}`} btn={btn} className={` `}/>
                ))}
              </div>
            </div>
            
          </div>            

        </div>
      </div>
    </section>
  );
};

